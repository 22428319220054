.players-container {
  align-content: stretch;
  
}

.player-card {
  display: flex;
  width: calc((100% - 30px) / 3);
  margin-bottom: 10px;
  margin: {
    top: 5px;
    bottom: 5px;
    right: 10px;
  }
  padding: 10px;
  background-color: $container-background;
}

// remove
.players-container.members {
  a:nth-child(-n+2){
    display: none;
  }
}

.player-card-inner {
  display: flex;
  width: 100%;
  img {
    height: 80px;
    width: 80px;
  }
  .player-description {
    color: #fff;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 300;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    background-color: $bg-color-tables;
    width: 100%;

    .flag-icon {
      margin-right: 10px;
    }

    .player-name {
      position: relative;
      margin-right: 20px;
      font-size: 14px;
      
      &:before {
        content: "";
        width: 1px;
        height: 20px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: -2px;
        right: -10px;
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
      }
    }
    .player-tag {
      font-size: 12px;
      font-weight: 200;
    }

  }
}

@media only screen and (min-width: 961px) {
  /*.player-card {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }*/
}

@media only screen and (max-width: 960px) {
  .player-card {
    display: flex;
    width: calc((100% - 10px) / 2);
    margin-bottom: 10px;
    margin: {
      top: 5px;
      bottom: 5px;
      right: 10px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  
    .player-description {
      color: #fff;
      padding: 10px 15px;
      font-size: 14px;
      font-weight: 300;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      background-color: $bg-color-tables;
      width: 100%;
  
      .player-name {
        position: relative;
        margin-right: 20px;
        
        &:before {
          content: "";
          width: 1px;
          height: 20px;
          background: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: -2px;
          right: -10px;
          -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
        }
      }
      .player-tag {
        font-size: 12px;
        font-weight: 200;
      }
  
    }
  }
}

@media only screen and (max-width: 767px) {
  .player-card {
    display: flex;
    width: calc(100%);
    margin-bottom: 10px;
    margin: {
      top: 5px;
      bottom: 5px;
      right: 10px;
    }
    &:nth-child(1n) {
      margin-right: 0;
    }
  }
}