form {
  max-width: calc(50% - 10px);
  display: block;
  background-color: $background-color-overlay-content;
  color: #fff;
  padding: 25px;
  margin-bottom: 25px;
  h3 {
    position: relative;
    margin-bottom: 20px;
    &:before {
        content: "";
        width: 1px;
        padding: 23px 0 0;
        background: white;
        position: absolute;
        top: 8px;
        left: -10px;
        -webkit-transform: translateY(-50%) rotate(30deg);
        transform: translateY(-50%) rotate(30deg);
    }
  }
  .form-w50 {
    width: calc(50% - 10px);
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 10px;
    label {
      display: block;
      font-size: 13px;
      font-weight: 300;
    }
    input {
      width: 100%;
      margin-top: 5px;
      background: transparent;
      border: 1px solid rgba(255,255,255,.6);
      height: 30px;
      color: #fff;
      padding-left: 5px;
      font-size: 14px;
      &:focus {        
        outline-color: $bg-color-elements;
      }        
    }
  }
  button {
    background-color: $bg-color-elements;
    border: none;
    color: #fff;
    padding: 8px 12px;
    text-align: center;
    &:hover {
      background-color: $bg-color-elements-hovered;
      cursor: pointer;
    }
  }
}

