@media only screen and (min-width: 768px) {
  .contents {
    article {
      max-width: 960px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      .heading {
        margin: 0 0 20px;
        font-family: PT Serif, serif;
        h3 {
          margin: 0;
          font-size: $article-title;
          color: #fff;
          line-height: 1.9;
          letter-spacing: 2px;
          span {
            padding: 5px 15px;
            background: rgba(191, 19, 117, 0.6);
          }
        }
      }
      .detail {
        margin-bottom: 40px;
        p {
          font-size: $article-p;
          color: #fff;
          line-height: 1.875;
          font-weight: 400;
          text-align: justify;
          margin: 0 0 15px;
        }
        &.flex-container {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            justify-content: flex-start;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .contents {
    article {
      width: 93.75%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      .heading {
        margin: 0 0 20px;
        h3 {
          margin: 0;
          font-size: $article-title-mob;
          color: #fff;
          line-height: 2.1;
          span {
            padding: 5px 6px;
            background: rgba(191, 19, 117, 0.6);
          }
        }
      }
      .detail {
        margin-bottom: 40px;
        p {
          font-size: $article-p-mob;
          color: #fff;
          line-height: 1.875;
          font-weight: 400;
          text-align: justify;
          margin: 0 0 15px;
        }
        &.flex-container {
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          justify-content: flex-start;
      }
      }
    }
  }
}
