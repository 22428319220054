.text-btn {
  position: relative;
}

.text-btn a:after {
  content: "";
  width: 5px;
  height: 5px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translate(0, -50%) rotate(45deg);
  transform: translate(0, -50%) rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .text-btn a:after {
    content: "";
    width: 2%;
    padding: 2% 0 0;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    position: absolute;
    top: 50%;
    right: 3%;
    -ms-transform: translate(0, -50%) rotate(45deg);
    transform: translate(0, -50%) rotate(45deg);
  }
}

.login a {
  padding: 8px 28px 10px 15px;
  background-color: rgba(191, 19, 117, 0.6);
  color: #fff;
  margin-top: 3px;
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  transition: 0.3s;
}

.login a i {
  margin-right: 5px;
  font-size: 18px;
}

.login a:hover {
  background-color: rgba(191, 19, 117, 0.8);
}

.detail .text-btn {
  margin: 0 auto;
}

.detail .text-btn a {
  width: 320px;
  margin: 0 auto;
  padding: 30px 0;
  display: block;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: rgba(191,19,117,.6);
  position: relative;
  transition: .3s;

  &:hover {
    background-color: $bg-color-elements-hovered;
  }
}