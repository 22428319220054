.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  #wrapper {
    position: relative;
  }

  main {
    display: block;
    margin: 0 auto;
    background-attachment: fixed;
  }

  .contents {
    width: 100%;
    max-width: 1150px;
    min-height: 650px;
    margin: 0 auto;
    padding: 60px 20px 120px;
    position: relative;

    h2 {
      margin: 0 0 84px 40px;
      font-size: 24px;
      letter-spacing: 3px;
      color: #fff;
      position: relative;
      z-index: 1;
      font-family: $title-font;
      text-transform: uppercase;

      &:before {
        content: "";
        width: 1px;
        height: 35px;
        background: white;
        position: absolute;
        top: -3.5px;
        left: -30px;
        -ms-transform: rotate(30deg);
        transform: rotate(30deg);
      }

      span {
        margin: 0 0 0 15px;
        font-size: 12px;
        letter-spacing: 1px;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        font-family: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", MS PGothic, sans-serif;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  main {
    display: block;
    position: relative;
  }

  .contents {
    width: 100%;
    padding: 40px 0 100px;
    position: relative;
    min-height: 650px;

    h2 {
      margin: 0 0 10% 7%;
      font-size: 20px;
      letter-spacing: 3px;
      color: #fff;
      position: relative;
      display: inline-block;
      font-family: $title-font;

      &:before {
        content: "";
        width: 1px;
        padding: 14% 0 0;
        background: white;
        position: absolute;
        top: 50%;
        left: -8.5%;
        -ms-transform: translateY(-50%) rotate(30deg);
        transform: translateY(-50%) rotate(30deg);
      }

      span {
        margin: 0 0 0 5px;
        font-size: calc(8px + 15 * ((100vw - 320px) / 704));
        letter-spacing: 1px;
        display: inline-block;
        font-family: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", MS PGothic, sans-serif;
      }
    }
  }
}

.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}

.w100 {
  width: 100%;
}
.w70 {
  width: 70%;
}
.w30 {
  width: 30%;
}
.w50 {
  width: 50%;
}

.flex-ac {
  align-items: center;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mandamientos {

  h3 {
    color: #fff;
  }
  p {
    font-weight: 300 !important;
    border-left: 5px solid $bg-color-elements;
    padding-left: 10px;
    margin: 10px 0 30px !important;
    &:last-child() {
      border: none;
      padding-left: 0;
    }
  }
  
} 