footer {
  width: 100%;
  background: #050707;
  position: relative;
}
footer:before {
  content: "";
  width: 100%;
  height: 107px;
  margin: 0 auto;
  background: url(../../images/bg.png) 50% 0 no-repeat;
  position: absolute;
  top: -59px;
  right: 0;
  left: 0;
}
footer .inner {
  max-width: 1280px;
  margin: 0 auto;
  padding: 82px 50px 25px;
  position: relative;
  text-align: center;
}

footer .inner .global-navi {
  margin: 0;
  display: inline-block;
}
footer .inner .global-navi ul li {
  float: left;
  list-style: none;
  position: relative;
}
footer .inner .global-navi ul li a {
  color: #fff;
  position: relative;
  display: block;
}
footer .inner .global-navi ul li a p {
  font-size: 17px;
  letter-spacing: 1px;
  position: relative;
  text-align: left;
  opacity: 0.5;
  font-family: PT Serif, serif;
}
footer .inner .global-navi ul li a p span {
  font-size: 10px;
  letter-spacing: 1px;
  font-family: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, "メイリオ",
    Meiryo, Osaka, "ＭＳ Ｐゴシック", MS PGothic, sans-serif;
}
footer .inner .global-navi ul li a[href]:before {
  content: "";
  width: 0;
  height: 24px;
  background: rgba(191, 19, 117, 0.6);
  position: absolute;
  top: -2.5px;
  left: -7px;
  transition: 0.3s;
}
footer .inner .global-navi ul li a[href]:hover:before {
  width: calc(100% + 14px);
}
footer .inner .global-navi ul li a[href] p {
  opacity: 1;
}
footer .inner .global-navi ul li:not(:first-child) {
  margin: 0 0 0 60px;
}
footer .inner .global-navi ul li:not(:first-child):before {
  content: "";
  width: 1px;
  height: 36px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: -30px;
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
footer .inner .global-navi ul .current a[href]:before {
  content: "";
  width: calc(100% + 14px);
  height: 24px;
  background: rgba(191, 19, 117, 0.6);
  position: absolute;
  top: -2.5px;
  left: -7px;
  transition: 0.3s;
}
footer .inner .global-navi ul li + li {
  margin: 20px 0 0 60px;
}
footer .inner .social-navi {
  display: block;
  margin-top: 20px;
}
footer .inner .social-navi ul li {
  display: inline-block;
}
footer .inner .social-navi ul li a img {
  max-height: 35px;
  transition: 0.3s;
}

footer .inner .social-navi ul li:not(:last-child) {
  margin-right: 8px;
}
footer .inner .social-navi ul li a:hover img {
  opacity: 0.6;
}

footer .inner .copyright {
  display: block;
  margin: 25px 0 0 0;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 767px) {
  .sp-b {
    display: block;
  }
  footer .inner {
    width: 100%;
    margin: 0 auto;
    padding: 10% 0 15%;
    position: relative;
  }
  footer .inner .global-navi ul li:not(:first-child) {
    margin: auto;
  }
  footer .inner .global-navi {
    width: 87.5%;
    margin: 6% auto 6%;
  }
  footer .inner .global-navi ul li {
    width: 50%;
    padding: 0 0 0 12.5%;
    float: left;
    list-style: none;
    position: relative;
    text-align: left;
  }
  footer .inner .global-navi ul li:nth-child(2) {
    margin: 0 !important;
  }
  footer .inner .global-navi ul li + li {
    margin: 5% 0 0 !important;
  }
  footer .inner .global-navi ul li:not(:first-child):before {
    display: none;
  }
  footer .inner .global-navi ul li a {
    color: #fff;
    position: relative;
    display: inline-block;
  }
}
